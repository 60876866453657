<template>
	<div>
		<div class="top-nav-bar"><TopNavBar @onClick="onClick" /></div>
		<div style="padding-top: 68px;margin:8px;">
			<div class="list" :class="i> 0?'margin-top-xs':''" v-for="(item, i) in list" :key="i">
				<div class="list-cell">
					<van-checkbox style="width: 24px;" v-model="item.checked" @change="changeSingeCheck"></van-checkbox>
					<div style="display: flex;padding-left: 10px;width: 80vw;">
						<div style="display: flex;justify-content: center;align-items: center;">
							<img :src="prtImg(item.files, 'prtPicture')" @click="toDetail(item)" />
						</div>
						<div style="padding-left: 20px;" class="text-cut">
							<div class="title text-cut">{{ prtName(item.product) }}</div>
							<CirCleTitle :text="'体积: '+ (prtLong(item.product) * prtInch(item.product)  *  prtHigh(item.product) )+' cm'"></CirCleTitle>
							<CirCleTitle :text="'含量: '+ (prtContent(item.product) || '暂无' )"></CirCleTitle>
							<div class="foot">
								<div class="flex-center">
									<div>
										<span class="money-icon">￥</span>
										<span class="price">{{item.prtPrice?item.prtPrice:'面议'}}</span>
									</div>
								</div>
								<div class="flex-center">
									<input v-model="item.num" class="price-input" />
								</div>
							</div>
							<!-- <div class="foot-right">
								<van-stepper v-model="item.num"  @change="changeNum(item)"/></div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<van-checkbox style="width: 124px;padding-left: 20px;" v-model="checkAll" @click="checkAllProduct">全选</van-checkbox>
			<div class="flex-center" v-if="showManage">
				<div class="btn" @click="generateOrder">生成进货单</div>
			</div>
			<template v-else>
				<div class="flex-center">
					<div style="color: #FF9C00;" @click="collections">移至收藏夹</div>
				</div>
				<div class="flex-center">
					<div class="btn" style="background: #FF9C00;width: 125px;" @click="deleteCart">删除</div>
				</div>
			</template>
		</div>
		<!-- 水印 -->
		<WaterMark />
	</div>
</template>

<script>
import TopNavBar from './components/TopNavBar.vue';
import CirCleTitle from '@/components/common/CircleTitle.vue'
import { getCart, generateOrder ,addToCart,removeCart,collections} from '@/api';
import { Toast } from 'vant';
export default {
	data() {
		return {
			checkAll: false,
			value: 0,
			list: [],
			showManage:true
		};
	},
	components: {
		TopNavBar,
		CirCleTitle
	},
	mounted() {
		this.getCart();
	},
	methods: {
		//修改数量
		changeNum(item){
			let param ={
				num:item.num,
				productId:item.productId,
				shopUuid:item.shopId,
				userId:item.userId
			}
			addToCart(param)
		},
		onClick(show) {
			this.showManage = show;
		},
		toDetail(item){
			this.$router.push({
				name: 'ProductDetails',
				query: { uuid: item.productId, title: item.product.prtName,shopUuid:this.$store.state.shopUuid }
			});
		},
		deleteCart(){
			let lists = [];
			this.list.map(resp => {
				if (resp.checked) {
					lists.push(resp.id);
				}
			});
			let param = {
				llist: lists
			};
			removeCart(param).then(res =>{
				if(res.state == 100){
					Toast.success('删除成功');
					this.getCart();
				}
			})
		},
		prtName(product){
			return product?product.prtName:'暂无'
		},
		prtLong(product){
			return product?.prtLong
		},
		prtInch(product){
			return product?.prtInch
		},
		prtHigh(product){
			return product?.prtHigh
		},
		prtContent(product){
			return product?.prtContent
		},
		//收藏
		collections(){
			let lists = [];
			this.list.map(resp => {
				if (resp.checked) {
					lists.push(resp.productId);
				}
			});
			let param ={
				state:0,
				slist:lists
			}
			collections(param).then(res =>{
				if(res.state == 100){
					Toast.success('成功添加到收藏夹');
				}
			})
		},
		// 单选
		changeSingeCheck() {
			let checkNum = [];
			this.list.map(resp => {
				if (resp.checked) {
					checkNum.push(resp);
				}
			});
			if (checkNum.length == this.list.length) {
				this.checkAll = true;
			} else {
				this.checkAll = false;
			}
		},
		// 生成进货单
		generateOrder() {
			let lists = [];
			this.list.map(resp => {
				if (resp.checked) {
					lists.push(resp.id);
				}
			});
			let param = {
				llist: lists
			};
			if (param.llist.length > 0) {
				generateOrder(param).then(res => {
					if(res.state == 100){
						Toast.success('已生成订货单');
						this.getCart();
					}
				});
			}
		},
		// 全选
		checkAllProduct() {
			this.list.map(resp => {
				resp.checked = this.checkAll;
			});
			this.$forceUpdate();
		},
		// 获取购物车数据
		getCart() {
			let userId = this.$store.state.userInfo?this.$store.state.userInfo.uuid :''
			getCart({
				shopUuid: this.$store.state.shopUuid,
				userId: userId
			}).then(res => {
				if (res.state == 100) {
					if (res.items) {
						res.items.map(resp => {
							resp.checked = false;
						});
					}
					this.list = res.items;
				}
				this.list = res.items;
			});
		},
		// 设置图片
		prtImg(items, b) {
			for (var i = 0; i < items.length; i++) {
				if (items[i].typeName == b) {
					return items[i].filePath;
				}
			}
		}
	}
};
</script>

<style lang="less" scoped="scoped">
.footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 60px;
	z-index: 999;
	background: #ffffff;
	box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: space-between;

	.flex-center {
		display: flex;
		justify-content: center;
		align-items: center;

		.btn {
			width: 174px;
			height: 39px;
			background: #ff3879;
			border-radius: 20px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 15px;
			color: #ffffff;
			font-weight: 400;
			margin-right: 20px;
		}
	}
}

.top-nav-bar {
	position: fixed;
	top: 0;
	width: 100%;
	height: 60px;
	z-index: 999;
}

.van-checkbox__label {
	// width: 100%;
}
.margin-top-xs{
	margin-top: 8px;
}
.list {
	display: flex;
	justify-content: center;

	.list-cell {
		width: 100%;
		border-radius: 8px;
		background-color: #FFFFFF;
		padding: 12px;
		display: flex;
		img {
			height: 97px;
			width: 97px;
			border-radius: 5px;
		}
		.title {
			font-size: 13px;
			font-family: HarmonyOS Sans SC;
			font-weight: 400;
			color: #000000;
			width: 100%;
			border-left: 3px solid #36C9FF;
			padding-left: 10px;
		}

		.foot-right {
			display: flex;
			padding-top: 5px;
		}
		.foot{
			display: flex;
			justify-content: space-between;
			width: 50vw;
			.money-icon{
				font-size: 12px;
				font-family: HarmonyOS Sans SC;
				font-weight: 400;
				color: #FF2D50;
			}
			.price{
				font-size: 18px;
				font-family: HarmonyOS Sans SC;
				font-weight: 500;
				color: #FF2D50;
				padding-left: 2px;
			}
			.price-input{
				width: 75px;
				height: 25px;
				background: #36C9FF;
				border-radius: 13px;
				color: #ffffff;
				font-size: 14px;
				font-family: HarmonyOS Sans SC;
				border: none;
				text-align: center;
				margin-right: 15px;
			}
		}
	}
}
.text-cut{
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
ul {
	list-style: none;
	counter-reset: count;
}

li {
	color: #969696;
	font-size: 11px;
}

li::before {
	content: ' • ';
	color: #36C9FF;
	font-family: Microsoft YaHei;
	font-weight: 400;
	font-size: 20px;
	padding-top: 5px;
}
</style>
