<template>
	<div >
		<div class="content">
			<div class="icon" />
			<span class="span">
				{{text}}
				<!-- <span class="text-theme-color">{{ info.prtContent?info.prtContent:'0/0' }}</span> -->
			</span>
		</div>
	</div>
</template>

<script>
	export default{
		data() {
			return {
				
			}
		},
		props:{
			text:{
				type:String,
				default:''
			}
		}
	}
</script>

<style lang="less" scoped>
	.content {
		display: flex;
		align-items: center;
		margin:10px 0;
		
		.icon {
			width: 6px;
			/*no*/
			height: 6px;
			/*no*/
			background: var(--theme-color);
			border-radius: 50%;
		}
	
		.span {
			font-size: 12px;
			font-family: HarmonyOS Sans SC;
			font-weight: 400;
			color: #646464;
			margin-left: 8px;
		}
	}
	
</style>